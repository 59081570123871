import { useEffect, useState, useCallback, useRef } from 'react';
import { LightningData } from '../interfaces/lightning';

const useWS = (url: string, reconnectInterval = 5000) => {
  const [messages, setMessages] = useState<LightningData[]>([]);
  const [status, setStatus] = useState('connecting');
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const shouldReconnect = useRef(true); // Control reconnect logic

  const debugLevel:number = 0; // 0 = no debug output, 1 = log connection status, 2 = log messages

  const connect = useCallback(() => {
    if (!shouldReconnect.current) return;  // Prevent reconnect if disabled
    const ws = new WebSocket(url);
    wsRef.current = ws;

    // Attach event handlers
    ws.onopen = () => {
      if (debugLevel > 0) {
        console.log('WebSocket connection established');
      }
      setStatus('open');
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };

    ws.onclose = (event) => {
      if (debugLevel > 0) {
        console.log('WebSocket connection was closed', debugLevel==2?event:'');
      }
      setStatus('closed');
      attemptReconnect();  // Try to reconnect on close if allowed
    };

    ws.onerror = (error) => {
      console.error('WebSocket encountered an error:', error);
      setStatus('error');
      attemptReconnect();  // Try to reconnect on error if allowed
    };

    ws.onmessage = (event) => {
      setMessages((prevMessages) => {
        const parsedData = JSON.parse(event.data); // Assuming the message is in JSON format

        if (parsedData.msg_type === "info" && debugLevel == 2) {
          console.log('Info message:', parsedData.msg);
        }

        if (parsedData.msg_type === "data") {
          return [...prevMessages, parsedData];
        }

        return prevMessages;
      });
    };
  }, [url]);

  const attemptReconnect = useCallback(() => {
    if (shouldReconnect.current && status !== 'open') {
      if (debugLevel > 0) {
        console.log(`Attempting to reconnect in ${reconnectInterval / 1000} seconds...`);
      }
      reconnectTimeoutRef.current = setTimeout(() => {
        connect();
      }, reconnectInterval);
    }
  }, [connect, reconnectInterval, status]);

  useEffect(() => {
    connect();  // Initial connection attempt

    // Cleanup on unmount
    return () => {
      disconnect();  // Stop reconnect attempts on unmount
    };
  }, [connect]);

  // Function to disconnect the WebSocket and stop reconnect attempts
  const disconnect = useCallback(() => {
    shouldReconnect.current = false;  // Disable reconnect logic
    if (wsRef.current) {
      if (debugLevel > 0) {
        console.log('Closing WebSocket connection');
      }
      wsRef.current.close();
      wsRef.current = null;
    }
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }
  }, []);

  // Function to send any message over the WebSocket
  const sendMessage = useCallback((message: string) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      if (debugLevel > 1) {
        console.log('Sending message:', message);
      }
      wsRef.current.send(message);
    } else {
      console.log('WebSocket is not open. Cannot send message.');
    }
  }, []);

  return { messages, status, sendMessage, reconnect: connect, disconnect }; 
};

export default useWS;
