import { useEffect, useState, useMemo } from "react";
import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import L from "leaflet";
import useWS from "../../hooks/useWS";
import lightningIcon from "../../assets/lightning.svg";
import { LightningData, StrikeData } from "../../interfaces/lightning";
import { RootState } from "../../store"
import { debounce} from "lodash";
import "./Lightning.css";

const lightningIconInstance = new L.Icon({
  iconUrl: lightningIcon,
  iconSize: new L.Point(25, 25),
  iconAnchor: new L.Point(12.5, 12.5),
});

const MAX_MARKERS = 500;  // Set a maximum number of markers
// The maximum distance from the user's location to query for lightning data
// might be a good parameter to put in the user settings using a slider or dropdown and be 
// limited to a minimum of maybe 50mi (80km) and a maximum of maybe 200mi (321km)

const Lightning = () => {
  const token = localStorage.getItem("token");
  const url = `wss://test.truweathersolutions.com/websocket?token=${token}`;
  const location = useSelector((state: RootState) => state.user.defaultLocation);
  const [lightningData, setLightningData] = useState<LightningData[]>([]);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const { messages, status, sendMessage, reconnect, disconnect } = useWS(url);
  const radius = 100

  const connectMessage = useMemo(() => ({
    message_type: "connect",
    dataFeed: "lightning",
    filterType: "point",
    lat: location.latitude,
    long: location.longitude,
    radius: radius,
  }), [location]);

  const disconnectMessage = useMemo(() => ({
    message_type: "disconnect",
    dataFeed: "lightning",
    filterType: "point",
    lat: location.latitude,
    long: location.longitude,
    radius: radius,
  }), [location]);

  useEffect(() => {
    setSubscribed(false);  // Reset subscription state when component mounts
  }, []);

  // Subscribing to lightning data
  useEffect(() => {
    if (status === "open" && !subscribed) {
      sendMessage(JSON.stringify(connectMessage));
      setSubscribed(true);
      setLoading(true);  // Set loading to true when subscribing
    }
  }, [status, subscribed, connectMessage, sendMessage]);

  useEffect(() => {
    if (status === "open") {
      // Send disconnect message for the old subscription
      if (subscribed) {
        sendMessage(JSON.stringify(disconnectMessage));
      }
      // Send connect message for the new subscription
      sendMessage(JSON.stringify(connectMessage));
      setSubscribed(true);
      setLoading(true);
    }
  }, [status, location, radius, sendMessage]);  

  // Reset the subscription when WebSocket closes or encounters an error
  useEffect(() => {
    if (status === "closed" || status === "error") {
      setSubscribed(false);  // Reset subscribed so it can re-subscribe on reconnect
    }
  }, [status]);

  // Cleanup WebSocket when component is unmounted
  useEffect(() => {
    return () => {
      disconnect();  // Ensure disconnect is called when the component unmounts
    };
  }, [disconnect]);

  // Handle incoming messages with debouncing
  const debouncedUpdateData = useMemo(() => {
    return debounce((newData) => { // debounce from lodash
      setLightningData((prevData) => {
        const updatedData = [...prevData, ...newData].reduce((acc, data) => {
          const exists = acc.find(
            (d:StrikeData) =>
              d.time === data.time &&
              d.latitude === data.latitude &&
              d.longitude === data.longitude
          );
          if (!exists) acc.push(data);
          return acc;
        }, [] as LightningData[]);

        // If the number of markers exceeds the limit, remove the oldest
        if (updatedData.length > MAX_MARKERS) {
          return updatedData.slice(-MAX_MARKERS);  // Keep only the most recent MAX_MARKERS
        }

        return updatedData;
      });
      setLoading(false);  // Stop loading when data is received
    }, 200);  // Debounce to update every 200ms
  }, []);

  useEffect(() => {
    const newMessages = messages.filter((msg) => msg.msg_type === "data");
    if (newMessages.length > 0) {
      debouncedUpdateData(newMessages);
    }
  }, [messages, debouncedUpdateData]);

  return (
    <>
      {loading && <div className="loading-indicator">Loading Lightning Data...</div>}
      
      {!loading && lightningData.map((data) => {
        if ('peakCurrent' in data) {
          // Now TypeScript knows 'data' is 'StrikeData'
          return (
            <Marker
              key={`${data.time}-${data.latitude}-${data.longitude}`}
              position={[parseFloat(data.latitude), parseFloat(data.longitude)]}
              icon={lightningIconInstance}
            >
              <Popup className="custom-popup">
                <div>
                  <p className="text-sm"><strong>Time:</strong> {new Date(data.time).toLocaleString()}</p>
                  <p className="text-sm"><strong>Type:</strong> {data.type}</p>
                  <p className="text-sm"><strong>Latitude:</strong> {data.latitude}</p>
                  <p className="text-sm"><strong>Longitude:</strong> {data.longitude}</p>
                  <p className="text-sm"><strong>Peak Current:</strong> {data.peakCurrent}</p>
                  <p className="text-sm"><strong>IC Height:</strong> {data.icHeight}</p>
                  <p className="text-sm"><strong>Number of Sensors:</strong> {data.numSensors}</p>
                  <p className="text-sm"><strong>EE Major:</strong> {data.eeMajor}</p>
                  <p className="text-sm"><strong>EE Minor:</strong> {data.eeMinor}</p>
                  <p className="text-sm"><strong>EE Bearing:</strong> {data.eeBearing}</p>
                </div>
              </Popup>
            </Marker>
          );
        } else {
          return null;
        }
      
      })}
    </>
  );
};

export default Lightning;
