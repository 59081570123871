import { useDispatch } from 'react-redux'
import { editData } from '../Components/Alerting/AlertingSlice/AlertingSlice'
import { latLng } from 'leaflet'

interface AlertParsedData {
  coordinates?: [[]]
  properties?: { [key: string]: any }
}
export function ALERTAPIParser(apiresponse: any[]) {
  const parsedArray: AlertParsedData[] = []
  apiresponse?.features?.forEach((feature) => {
    if (feature.geometry.type === 'Polygon') {
      const saved: AlertParsedData | number[] = []
      if (feature.hazardType === 'WINDS_RTMA') {
        feature.complexGeometry.coordinates.forEach((latlng: Array<number>) => {
          const temp = latlng[0]
          latlng[0] = latlng[1]
          latlng[1] = temp
          saved.push(latlng)
        })
      } else {
        feature.complexGeometry.coordinates.forEach((latlng: Array<number>) => {
          for (let index = 0; index < latlng.length; index++) {
            const temp = latlng[index][0]
            latlng[index][0] = latlng[index][1]
            latlng[index][1] = temp
          }
          saved.push(latlng)
        })
      }
      const newObject: AlertParsedData = {
        coordinates: saved,
        properties: feature.properties,
        type: feature.geometry.type,
      }
      parsedArray.push(newObject)
    } else if (feature.geometry.type === 'LineString') {
      var saved: AlertParsedData | number[] = []
      const temp_arr: AlertParsedData[] = []
      feature.complexGeometry.coordinates.forEach((latlng) => {
        const modifiedLatLng = [latlng[1], latlng[0]]
        temp_arr.push(modifiedLatLng)
      })
      saved.push(temp_arr)
      const newObject: AlertParsedData = {
        coordinates: saved,
        properties: feature.properties,
        type: feature.complexGeometry.type,
      }
      parsedArray.push(newObject)
    } else {
      var saved: AlertParsedData | number[] = []
      const newObject: AlertParsedData = {
        coordinates: feature.geometry.coordinates,
        properties: feature.properties,
        type: feature.geometry.type,
      }
      parsedArray.push(newObject)
    }
  })
  return [parsedArray, apiresponse.eventCount]
}

export function ALERTEDITAPIParser(apiresponse) {
  const parsedArray: any[] = []
  const date = new Date().toISOString()
  apiresponse?.forEach((feature) => {
    if (feature.endTime > date) {
      parsedArray.push(feature)
    }
  })
  return parsedArray
}

export function EventParser(apiresponse: any[]) {
  const parsedArray: AlertParsedData[] = []
  apiresponse?.forEach((event) => {
    if (event.message.geometry.coordinates.length > 0) {
      const saved: AlertParsedData[] = []

      if (event.message.geometry.type === 'LineString') {
        const temp_arr: AlertParsedData[] = []
        event.message.geometry.coordinates.forEach((latlng) => {
          const modifiedLatLng = [latlng[1], latlng[0]]
          temp_arr.push(modifiedLatLng)
        })
        saved.push(temp_arr)
      } else if (event.message.geometry.type === 'MultiPolygon') {
        const temp_arr: AlertParsedData[] = []
        event.message.geometry.coordinates.forEach((latlng) => {
          latlng.forEach((element) => {
            const modifiedLatLng = Array.isArray(element)
              ? element.map((element) => [element[1], element[0]])
              : element.map((element) => [element[1], element[0]])
            temp_arr.push(modifiedLatLng)
          })
        })
        saved.push(temp_arr)
      } else {
        const temp_arr: AlertParsedData[] = []
        event.message.geometry.coordinates.forEach((latlng) => {
          const modifiedLatLng = Array.isArray(latlng)
            ? latlng.map((coordinates) => [coordinates[1], coordinates[0]])
            : latlng
          temp_arr.push(modifiedLatLng)
        })
        saved.push(temp_arr)
      }
      const newProperties = {
        type: 'Event',
        shapeType: event.message.geometry.type,
      }
      const bufferGeometry = event.message.bufferGeometry.coordinates
      const _bufferGeometry = []
      for (const i in bufferGeometry) {
        const polygon = []
        for (const j in bufferGeometry[i]) {
          // console.log(bufferGeometry[i][j][0] )
          // bufferGeometry[i][j][0] = bufferGeometry[i][j][1]
          polygon.push([bufferGeometry[i][j][1], bufferGeometry[i][j][0]])
        }
        _bufferGeometry.push(polygon)
      }
      const newObject: AlertParsedData = {
        coordinates: saved,
        bufferGeometry: [_bufferGeometry],
        properties: { ...event.message.properties, ...newProperties },
      }
      parsedArray.push(newObject)
    }
  })
  return parsedArray
}
