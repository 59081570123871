import { useState, useEffect } from 'react'
import chevronUp from '../../../assets/chevron-up.svg'
import chevron from '../../../assets/chevron.svg'
import SlideOverDialogEvents from '../../../menus/SlideOverDialog/SlideOverDialogEvents'
import getActiveEvents from '../AlertingSlice/getActiveAlerts'
import useAlertSetting from '../useAlertSetting'
import { elements } from '../../../interfaces/alertingelements'
import { useDispatch } from 'react-redux'
import {
  callSpecificAlert,
  removeAlertForParticularHazard,
} from '../AlertingSlice/AlertingSlice'

interface AlertToggleBtn {
  '250FT Wind Speed': boolean
  'Surface Wind Speed': boolean
  'Surface Wind Gust': boolean
  Visibility: boolean
  Ceiling: boolean
  Precipitation: boolean
  Temperature: boolean
  Lightning: boolean
  'NWS Alerts': boolean
}

export const AlertListBox = () => {
  const [openedTab, setOpenedTab] = useState<string>('Alert')
  const [isOpened, setIsOpened] = useState<boolean>(true)
  const [alertData, setAlertData] = useState([])
  const [activeHazard, setActiveHazard] = useState<Array<string>>([])
  const [content, setContent] = useState(<></>)
  const [transition, setTransition] = useState(
    'flex flex-row translate-x-52 transition-all duration-500'
  )
  const [alertBtnChecked, setAlertBtnChecked] = useState<AlertToggleBtn>({
    '250FT Wind Speed': true,
    'Surface Wind Speed': true,
    'Surface Wind Gust': true,
    Visibility: true,
    Ceiling: true,
    Precipitation: true,
    Temperature: true,
    Lightning: true,
    'NWS Alerts': true,
  })
  const alertTitles = [
    '250FT Wind Speed',
    'Surface Wind Speed',
    'Surface Wind Gust',
    'Visibility',
    'Ceiling',
    'Precipitation',
    'Temperature',
    'Lightning',
    'NWS Alerts',
  ]

  const hazardType_label_map = {
    TEMPERATURE: 'Temperature',
    WINDS_80M: '250FT Wind Speed',
    WINDS_RTMA: 'Surface Wind Speed',
    VISIBILITY: 'Visibility',
    WINDGUSTS_RTMA: 'Surface Wind Gust',
    CEILINGS: 'Ceiling',
    MRMS_PRECIP: 'Precipitation',
    LIGHTNING_ALERT: 'Lightning',
    TOR_WARNING_NWS: 'NWS Alerts',
    SVR_WARNING_NWS: 'NWS Alerts',
  }

  const { addAlertSideNav, dismissAlert } = useAlertSetting()
  const dispatch = useDispatch()

  useEffect(() => {
    getActiveEvents().then((data) => {
      if (data[0].length > 0) {
        setAlertData(data)
        const hazards = new Set()
        data
          .filter((el) => typeof el === 'object')
          .map((ele) =>
            ele.forEach((e) => {
              hazards.add(hazardType_label_map[e.properties.hazardType])
            })
          ),
          setActiveHazard([...Array.from(hazards)])
        for (const c of hazards) {
          addAlertSideNav(c)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (isOpened) {
      setContent(<img className="w-5 h-5" src={chevron} alt="" />)
      setTransition('flex flex-row translate-x-0 transition-all duration-500')
    } else {
      setContent(<img src={chevronUp} alt="" />)
      setTransition('flex flex-row translate-x-52 transition-all duration-500')
    }
  }, [isOpened])

  const colorCodeIndiviualAlerts = (alertLabel: string) => {
    let color = '#'
    if (alertLabel == 'NWS Alerts') {
      color += 'FFA500'
    } else {
      color += elements.find((ele) => ele.id == alertLabel)?.color
    }
    return color
  }

  const handleAlertToggleBtnClick = (alertName: string) => {
    setAlertBtnChecked((prev) => {
      //my brain broke trying to figure out what to do here. I wanted to
      //the flip the value of alertName key in AlertToggleBtn. If there is a better way,
      //let me know
      const value = !prev[alertName as keyof AlertToggleBtn]
      prev[alertName as keyof AlertToggleBtn] = value

      const newState = {
        ...prev,
      }
      return newState
    })
    console.log(alertName)
    addAlertSideNav(alertName)
    dispatch(callSpecificAlert(true))
  }

  return (
    <div
      className={`z-[99999998] flex flex-row absolute left-0 top-0 h-80 bg-none ${
        isOpened ? 'w-1/3' : 'w-1/6'
      }`}
    >
      {isOpened && (
        <div className="flex flex-col w-5/6">
          <div className="flex flex-row w-full">
            <button
              className={`${
                openedTab === 'Alert'
                  ? 'bg-green-800 hover:bg-green-600'
                  : 'bg-gray-800 hover:bg-gray-600'
              } h-10 w-1/2 text-white font-bold py-2 px-2}`}
              onClick={() => setOpenedTab('Alert')}
            >
              <div className="h-full w-full flex justify-center items-center flex-row">
                <span className="tracking-wider text-center p-1">Alerts</span>
              </div>
            </button>
            <button
              className={`${
                openedTab === 'Event'
                  ? 'bg-green-800 hover:bg-green-600'
                  : 'bg-gray-800 hover:bg-gray-600'
              } h-10 w-1/2 text-white font-bold py-2 px-2}`}
              onClick={() => setOpenedTab('Event')}
            >
              <div className="h-full w-full flex justify-center items-center flex-row">
                <span className="tracking-wider text-center p-1">
                  Event List
                </span>
              </div>
            </button>
          </div>
          <div className="flex text-lg min-h-72 max-h-72 h-72 w-full flex-row justify-between">
            {openedTab === 'Alert' && (
              <ul className="w-full p-2 bg-white">
                {alertTitles.map((el) => (
                  <div
                    className="w-full flex flex-row justify-start align-start"
                    key={el}
                  >
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        onClick={() => handleAlertToggleBtnClick(el)}
                        checked={
                          activeHazard.includes(el) &&
                          alertBtnChecked[el as keyof AlertToggleBtn]
                        }
                        disabled={!activeHazard.includes(el)}
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    <div className="w-1/6 flex justify-center align-center">
                      <svg
                        fill={`${
                          activeHazard.includes(el)
                            ? colorCodeIndiviualAlerts(el)
                            : '#9E9E9E'
                        }`}
                        className="w-5 h-5"
                        viewBox="0 0 28 25"
                      >
                        <path d="M15.25 15.5H12.75V9.25H15.25M15.25 20.5H12.75V18H15.25M0.25 24.25H27.75L14 0.5L0.25 24.25Z" />
                      </svg>
                    </div>
                    <div
                      className={`
                        w-5/6 text-start`}
                      style={{
                        color: `${
                          activeHazard.includes(el)
                            ? colorCodeIndiviualAlerts(el)
                            : 'black'
                        }`,
                      }}
                    >
                      {el}
                    </div>
                  </div>
                ))}
              </ul>
            )}
            {openedTab === 'Event' && <SlideOverDialogEvents />}
          </div>
        </div>
      )}
      <div
        className={`flex flex-col h-80 bg-black rounded-br-xl
        ${isOpened ? 'w-1/6' : 'w-1/3'}`}
      >
        <button
          className="h-full w-full bg-black rounded-br-xl"
          onClick={() => setIsOpened(!isOpened)}
          type="button"
        >
          <div className="w-full flex flex-row rotate-90">
            <span className="h-full w-full mr-10 pointer-events-auto text-xl text-white whitespace-nowrap">
              Alert Menu
            </span>
            {content}
          </div>
        </button>
      </div>
    </div>
  )
}
