import { TWS_API_URL } from '../../environment/apis.config'
import store from '../../store'

export const createSavedView= async (name,selectedItems)=> {
    const state = store.getState()
    const userId = state.user.id
    const url = `${TWS_API_URL}/savedProfile/${userId}` //${localStorage.getItem('userId')}`
    const requestOptions = {
        method: 'POST',
        headers: {
        Authorization: `Bearer ` + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "profileName": name,
            "profile": selectedItems
        }),
    }
    try {
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        return data
    } catch (error) {
        console.error('Error:', error)
        throw error
    }

}

export const fetchSavedProfiles = async () => {
    const state = store.getState()
    const userId = state.user.id
    const url = `${TWS_API_URL}/savedProfile/${userId}` //${localStorage.getItem('userId')}`
    const requestOptions = {
        method: 'GET',
        headers: {
        Authorization: `Bearer ` + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        }
    }
    try {
        if (userId === null) {
            return []
        }
        const response = await fetch(url, requestOptions)
        const data = await response.json() 
        return data
    } catch (error) {
        return [] // on error, return an empty array otherwise, it 
                  // will try to use the user id of the last user to sign in ( which is bad, m-kay.)
        // console.error('Error:', error)
        // throw error
    }
}

export const deleteSavedView = async (profileID) => {
    const url = `${TWS_API_URL}/savedProfile/${profileID}`
    const requestOptions = {
        method: 'DELETE',
        headers: {
        Authorization: `Bearer ` + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        }
    }
    try {
        const response = await fetch(url, requestOptions)
        return 
    } catch (error) {
        console.error('Error:', error)
        throw error
    }
}





