import { useState, useRef, useEffect } from 'react'
import { buttonContainerStyles, buttonStyles } from './popupStyles'
import { useMap } from 'react-leaflet'
import L from 'leaflet'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import useTWSMapContext from '../../../hooks/UseTWSContext'
import { addNewUserLocation, setDefaultLocation } from '../UserSlice'
import { useDispatch, useSelector } from 'react-redux'
import store, { RootState } from '../../../store'
import { DeleteLocation } from '../../../App'
import {
  appendLocationMenu,
  clearSelectedItems,
  selectedItems,
  setActiveMenuItem,
  setSelectedItemIds,
} from '../../../menus/SideNavigation/SideNavigationSlice'
import { IMenuItem } from '../../../menus/SideNavigation/SideNavItems'
import marker from '../../../assets/marker.svg'

const AddNewLocation = () => {
  const [latLng, setLatLng] = useState<Array<string>>([])
  const [locationName, setLocationName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const leafletMap = useMap()
  let newMarker: any = null
  const errorStatus = useErrorStatus()
  const { removeFromTWS } = useTWSMapContext()
  const dispatch = useDispatch()
  const userId = useSelector((state: RootState) => state.user.id)
  const menuCallback = (extraData: any) => {
    return (menuItem: IMenuItem, event?: SyntheticEvent) => {
      console.log(extraData)
      dispatch(setDefaultLocation({ ...extraData }))
      dispatch(
        setActiveMenuItem({
          id: extraData.name,
          active: true,
        })
      )
      // sessionStorage.setItem('defaultLocation', extraData.item.id)
      localStorage.setItem('defaultLocation', extraData.id)
    }
  }

  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
    const mapContainer = document.getElementsByClassName("leaflet-container")[0]
    mapContainer.style.cursor = `url("https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png") 4 4, crosshair`
  }, [])

  const handleMapClickEvent = (e) => {
    setLatLng([e.latlng.lat, e.latlng.lng])
    if (newMarker) {
      leafletMap.removeLayer(newMarker)
    }
    newMarker = L.marker(e.latlng).addTo(leafletMap)
  }

  const handleLocationSubmit = async () => {
    setIsLoading(true)
    if (locationName === '') {
      errorStatus.addMessage(
        'Location Name is needed before you can add this location',
        400,
        'error',
        true
      )
      setIsLoading(false)
      return
    }
    if (latLng.length !== 2) {
      errorStatus.addMessage(
        'Location coordinates are needed before you can add this location',
        400,
        'error',
        true
      )
      setIsLoading(false)
      return
    }
    const newLocationResponse = await dispatch(
      addNewUserLocation({
        latitude: latLng[0],
        longitude: latLng[1],
        name: locationName,
        innerRange: 5,
        outerRange: 20,
        clientOwner: userId,
      })
    )
    if (newLocationResponse.type.endsWith('rejected')) {
      errorStatus.addMessage(
        'Something went wrong. Please try again',
        400,
        'error',
        true
      )
    }
    const newLocationId = newLocationResponse?.payload?.id // needed for delete component
    const newLocationName = newLocationResponse?.payload?.name // for display
    const newItem = {
      id: 'locations-' + locationName,
      label: locationName,
      link: '',
      icon: marker,
      specialComponent: (
        <DeleteLocation id={newLocationId} menuIdString={locationName} />
      ),
      callback: menuCallback({
        latitude: latLng[0],
        longitude: latLng[1],
        name: locationName,
        innerRange: 5,
        outerRange: 20,
        clientOwner: userId,
      }),
      multiSelect: false,
      subMenu: [],
    }
    // dispatch(prependMenuItem({ item: newItem, parentId: 'location-menu' }))
    dispatch(appendLocationMenu({ item: newItem, parentId: 'location-menu' }))
    dispatch(clearSelectedItems())
    dispatch(selectedItems('Location'))
    dispatch(selectedItems(['location-menu', 'locations-' + newLocationName]))
    const newSelection = [
      ...store.getState().sideNav.selectedItemIds,
      'locations-' + newLocationName,
    ]
    if (newSelection.includes('add-location')) {
      newSelection.splice(newSelection.indexOf('add-location'), 1)
      dispatch(setSelectedItemIds(newSelection))
    }
    setIsLoading(false)
    disableAddLocation()
    leafletMap.removeEventListener('click')
    const mapContainer = document.getElementsByClassName("leaflet-container")[0]
    mapContainer.style.cursor = 'default'
  }

  const disableAddLocation = () => {
    removeFromTWS('add-location')
  }

  leafletMap.on('click', handleMapClickEvent)

  return (
    <>
      <div
        id="location-popup"
        onMouseOver={() => {
          leafletMap.removeEventListener('click')
          leafletMap.dragging.disable()
          leafletMap.doubleClickZoom.disable()
        }}
        onMouseOut={() => {
          leafletMap.addEventListener('click', handleMapClickEvent)
          leafletMap.dragging.enable()
          leafletMap.doubleClickZoom.enable()
        }}
        onFocus={() => {
          leafletMap.dragging.disable()
          leafletMap.doubleClickZoom.disable()
        }}
        onBlur={() => {
          leafletMap.dragging.enable()
          leafletMap.doubleClickZoom.enable()
        }}
        className="absolute top-0 left-0 z-[9999998] w-1/4 m-2 col-span-12 sm:col-span-6 md:col-span-3"
      >
        <button
          type="button"
          className="bg-white absolute top-0 right-0 rounded-md p-2 inline-flex items-center justify-center text-gray-400"
          onClick={disableAddLocation}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="flex flex-row bg-white shadow-sm rounded p-4">
          <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500">
            <svg
              width="20"
              height="27"
              fill="none"
              viewBox="0 0 20 27"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#fff"
                d="M10 0.5C14.1375 0.5 17.5 3.825 17.5 7.9375C17.5 13.5125 10 21.75 10 21.75C10 21.75 2.5 13.5125 2.5 7.9375C2.5 3.825 5.8625 0.5 10 0.5ZM10 5.5C9.33696 5.5 8.70107 5.76339 8.23223 6.23223C7.76339 6.70107 7.5 7.33696 7.5 8C7.5 8.66304 7.76339 9.29893 8.23223 9.76777C8.70107 10.2366 9.33696 10.5 10 10.5C10.663 10.5 11.2989 10.2366 11.7678 9.76777C12.2366 9.29893 12.5 8.66304 12.5 8C12.5 7.33696 12.2366 6.70107 11.7678 6.23223C11.2989 5.76339 10.663 5.5 10 5.5ZM20 21.75C20 24.5125 15.525 26.75 10 26.75C4.475 26.75 0 24.5125 0 21.75C0 20.1375 1.525 18.7 3.8875 17.7875L4.6875 18.925C3.3375 19.4875 2.5 20.2625 2.5 21.125C2.5 22.85 5.8625 24.25 10 24.25C14.1375 24.25 17.5 22.85 17.5 21.125C17.5 20.2625 16.6625 19.4875 15.3125 18.925L16.1125 17.7875C18.475 18.7 20 20.1375 20 21.75Z"
              />
            </svg>
          </div>
          <div className="flex flex-col flex-grow ml-4">
            <div className="text-md text-black-500">Add New Location</div>
            <div className="text-sm text-gray-500">
              <label htmlFor="location-coordinates">Coordinates</label>
              <input
                id="location-coordinates"
                value={latLng}
                ref={inputRef}
                onChange={(e) => {
                  e.preventDefault()
                  setLatLng(e.target.value.split(',').map((el) => el.trim()))
                }}
                className={`border border-gray-300 text-gray-900 text-base 
                  rounded-lg block w-full !important h-8 pl-2`}
              />
            </div>
            <div className="text-sm text-gray-500">
              <label htmlFor="location-coordinates">Location Name</label>
              <input
                id="location-coordinates"
                value={locationName}
                onChange={(e) => {
                  e.preventDefault()
                  setLocationName(e.target.value)
                }}
                className={`border border-gray-300 text-gray-900 text-base 
                  rounded-lg block w-full !important h-8 pl-2`}
              />
            </div>
            {!isLoading && (
              <div style={buttonContainerStyles}>
                <button style={buttonStyles} onClick={handleLocationSubmit}>
                  Submit
                </button>
              </div>
            )}
            {isLoading && (
              <div style={buttonContainerStyles}>
                <button disabled type="button" style={buttonStyles}>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewLocation
