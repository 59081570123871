export interface Element {
  id: string
  value: string
  label: string
  units: string
  color: string
  fill: boolean
  line: string
  edit: string
  opacity: number
}

export default interface AlertingElements {
  elements: Element[]
}

export const elements: Element[] = [
  {
    id: '250FT Wind Speed',
    value: 'WINDS_80M',
    label: '250FT Wind Speed',
    units: 'kts',
    color: 'CD853F',
    fill: true,
    line: 'dashed',
    edit: '80M Winds',
    opacity: 0.3,
  },
  {
    id: 'Surface Wind Speed',
    value: 'WINDS_RTMA',
    label: 'Surface Wind Speed',
    units: 'kts',
    color: 'DAA520',
    fill: true,
    line: 'dashed',
    edit: 'Surface Winds',
    opacity: 0.3,
  },
  {
    id: 'Surface Wind Gust',
    value: 'WINDGUSTS_RTMA',
    label: 'Surface Wind Gust',
    units: 'kts',
    color: 'B8860B',
    fill: true,
    line: 'dashed',
    edit: 'Surface Wind Gusts',
    opacity: 0.3,
  },
  {
    id: 'Ceiling',
    value: 'CEILINGS',
    label: 'Ceiling',
    units: 'kts',
    color: '00BFFF',
    fill: true,
    line: 'dashed',
    edit: 'Ceilings',
    opacity: 0.3,
  },
  {
    id: 'Visibility',
    value: 'VISIBILITY',
    label: 'Visibility',
    units: 'miles',
    color: '708090',
    fill: true,
    line: 'dashed',
    edit: 'Visibility',
    opacity: 0.7,
  },
  {
    id: 'Precipitation',
    value: 'MRMS_PRECIP',
    label: 'Precipitation',
    units: 'dbz',
    color: '00FF7F',
    fill: true,
    line: 'dashed',
    edit: 'Precipitation',
    opacity: 0.3,
  },
  {
    id: 'Temperature',
    value: 'TEMPERATURE',
    label: 'Temperature',
    units: 'degrees_f',
    color: 'C71585',
    fill: true,
    line: 'dashed',
    edit: 'Temperature',
    opacity: 0.3,
  },
  {
    id: 'Lightning',
    value: 'LIGHTNING_ALERT',
    label: 'Lightning',
    units: '',
    color: 'FFFF00',
    fill: false,
    line: 'solid',
    edit: 'Lightning',
    opacity: 0.3,
  },
  {
    id: 'NWS Tornado Warning',
    value: 'TOR_WARNING_NWS',
    label: 'NWS Tornado Warning',
    units: '',
    color: 'FF0000',
    fill: true,
    line: 'dashed',
    edit: 'NWS Tornado Warning',
    opacity: 0,
  },
  {
    id: 'NWS Severe Thunderstorm Warning',
    value: 'SVR_WARNING_NWS',
    label: 'NWS Severe Thunderstorm Warning',
    units: '',
    color: 'FFA500',
    fill: true,
    line: 'dashed',
    edit: 'NWS Severe Thunderstorm Warning',
    opacity: 0,
  },
]
