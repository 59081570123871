// componentSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ComponentState {
  componentKeys: string[];
}

const initialState: ComponentState = {
  componentKeys: [],
};

const componentSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    addComponentKey(state, action: PayloadAction<string>) {
      state.componentKeys.push(action.payload);
    },
    removeComponentKey(state, action: PayloadAction<string>) {
      state.componentKeys = state.componentKeys.filter(
        (key) => key !== action.payload
      );
    },
  },
});

export const { addComponentKey, removeComponentKey } = componentSlice.actions;
export default componentSlice.reducer;
