/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties, useEffect, useState } from 'react'
import { getCameras } from './CamNetwork.service'
import { CamFeature, Camera } from '../../interfaces/cam-network'
import L from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
import cameraIcon from '../../assets/camera.svg'
import './CameraNetwork.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import useErrorStatus from '../../hooks/UseErrorStatus'
import {
  deselectMenuItem,
  deselectMenuItemById,
} from '../../menus/SideNavigation/SideNavigationSlice'

interface CameraNetworkProps {
  position: L.LatLngTuple
}

// async function fetchApiData(latLng: L.LatLngTuple): Promise<Array<CamFeature>> {
//   const data = await getCameras(latLng)
//   return data
// }

async function fetchApiData(
  latLng: L.LatLngTuple
): Promise<[Array<CamFeature> | null, Error?]> {
  const [data, error] = await getCameras(latLng)
  return [data, error] // This will return both data and error
}

const CameraNetwork: React.FC<CameraNetworkProps> = ({ position }) => {
  const [apiData, setApiData] = useState<Array<CamFeature> | null>([])
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const errorStatus = useErrorStatus()
  const [hasError, setHasError] = useState(false)
  const dispatch = useDispatch()

  const fetchData = async () => {
    if (hasError) return
    const [data, error] = await fetchApiData(position)
    console.log('.>>> data is ', data)
    if (error) {
      errorStatus.addMessage(error.message, 400, 'error', false)
      dispatch(deselectMenuItemById('camera-network'))
      setHasError(true)
    } else {
      console.log(apiData)
      setApiData(data)
      setHasError(false)
    }
  }
  useEffect(() => {
    if (!hasError) {
      fetchData()
    }

  }, [position])

  const camIcon = new L.Icon({
    iconUrl: cameraIcon,
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    popupAnchor: [0, -12],
    className: darkMode === true ? 'brightness-200 contrast-200' : '',
  })

  return (
    <div>
      {apiData.map((item, index) => (
        <Marker
          key={index}
          position={[
            item.geometry.coordinates[1],
            item.geometry.coordinates[0],
          ]}
          icon={camIcon}
        >
          <Popup className="camera-popup" minWidth={330}>
            <div>
              {item.properties.hasOwnProperty('cameras') &&
              item.properties?.description?.value !== null ? (
                <div>
                  <CameraComponent
                    cameras={item.properties.cameras.value}
                    style={{ userSelect: 'none' }}
                  />
                </div>
              ) : (
                <></>
              )}
              {item.properties.hasOwnProperty('region') &&
              item.properties?.description?.value !== null ? (
                <div>
                  <strong>{item.properties.region?.label}:</strong>&nbsp;
                  <span>{item.properties.region?.value}</span>
                </div>
              ) : (
                <></>
              )}
              {item.properties.hasOwnProperty('description') &&
              item.properties?.description?.value !== null ? (
                <div>
                  <strong>{item.properties.description?.label}:</strong>&nbsp;
                  <span>{item.properties.description?.value}</span>
                </div>
              ) : (
                <></>
              )}
              {item.properties.hasOwnProperty('time') &&
              item.properties?.time?.value !== null ? (
                <div>
                  <strong>{item.properties.time?.label}:</strong>&nbsp;
                  <span>{item.properties.time?.value}</span>
                </div>
              ) : (
                <></>
              )}
              {item.properties.hasOwnProperty('visibility') &&
              item.properties?.visibility?.value !== null ? (
                <div>
                  <strong>{item.properties.visibility?.label}:</strong>&nbsp;
                  <span>{item.properties.visibility?.value}</span>
                </div>
              ) : (
                <></>
              )}
              {item.properties.hasOwnProperty('precip') &&
              item.properties?.precip?.value !== null ? (
                <div>
                  <strong>{item.properties.precip?.label}:</strong>&nbsp;
                  <span>{item.properties.precip?.value}</span>
                </div>
              ) : (
                <></>
              )}
              {item.properties.hasOwnProperty('roadWeather') &&
              item.properties?.roadWeather?.value !== null ? (
                <div>
                  <strong>{item.properties.roadWeather?.label}:</strong>&nbsp;
                  <span>{item.properties.roadWeather?.value}</span>
                </div>
              ) : (
                <></>
              )}
              {item.hasOwnProperty('id') && item?.id !== null ? (
                <div>
                  <span
                    className="text-gray-500"
                    style={{ fontSize: '0.65rem' }}
                  >
                    ID:{item?.id}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Popup>
        </Marker>
      ))}
    </div>
  )
}

interface ICameraComponentProps {
  cameras: Array<Camera>
  style?: CSSProperties
}
export const CameraComponent = (props: ICameraComponentProps) => {
  const [selectedCamera, setSelectedCamera] = useState<Camera>()
  const [cameraIndex, setCameraIndex] = useState<number>(0)
  const [camUrl, setCamUrl] = useState<string>()
  let multipleCameras = false
  if (props.cameras) {
    multipleCameras = props.cameras.length > 1 ? true : false
  }
  useEffect(() => {
    if (multipleCameras) {
      setSelectedCamera(props.cameras[0])
      setCamUrl(props.cameras[0].fullPath)
    } else {
      setSelectedCamera(props.cameras[0])
      setCamUrl(props.cameras[0].fullPath)
    }
  }, [props.cameras, multipleCameras])

  const handleRightArrow = () => {
    if (cameraIndex === props.cameras.length - 1) {
      setSelectedCamera(props.cameras[0])
      setCamUrl(props.cameras[0].fullPath)
      setCameraIndex(0)
    } else {
      setSelectedCamera(props.cameras[cameraIndex + 1])
      setCamUrl(props.cameras[cameraIndex + 1].fullPath)
      setCameraIndex(cameraIndex + 1)
    }
  }

  const handleLeftArrow = () => {
    if (cameraIndex === 0) {
      setSelectedCamera(props.cameras[props.cameras.length - 1])
      setCamUrl(props.cameras[props.cameras.length - 1].fullPath)
      setCameraIndex(props.cameras.length - 1)
    } else {
      setSelectedCamera(props.cameras[cameraIndex - 1])
      setCamUrl(props.cameras[cameraIndex - 1].fullPath)
      setCameraIndex(cameraIndex - 1)
    }
  }

  return (
    <>
      <div
        className="inline-block relative w-fit h-fit m-2"
        style={props.style}
      >
        <div className="inline-block w-80 h-fit">
          <img src={camUrl} alt="Camera" width="320" height="204" />
        </div>
        {multipleCameras ? (
          <div
            onClick={handleLeftArrow}
            className="absolute top-1/2 bg-black bg-opacity-50 text-white p-2 left-0 cursor-pointer hover:bg-opacity-25"
            style={{ transform: 'translateY(-50%)' }}
          >
            🡠
          </div>
        ) : (
          <></>
        )}
        {multipleCameras ? (
          <div
            onClick={handleRightArrow}
            className="absolute top-1/2 bg-black bg-opacity-50 text-white p-2 right-0 cursor-pointer hover:bg-opacity-25"
            style={{ transform: 'translateY(-50%)' }}
          >
            🡢
          </div>
        ) : (
          <></>
        )}
        <div className="absolute top-0 bg-white bg-opacity-50 text-black p-0.5 left-0">
          {selectedCamera?.direction}
        </div>
      </div>
    </>
  )
}

export default CameraNetwork
