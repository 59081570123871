import { TWS_API_URL } from '../../environment/apis.config'
import { FeatureType, Camera, CamFeature } from '../../interfaces/cam-network'
import { timeouts } from '../../services/RequestTimeouts'

export async function getCameras(
  location: L.LatLngTuple
): Promise<[Array<CamFeature> | null, Error?]> {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => {
    controller.abort()
    clearTimeout(timeoutId)
  }, timeouts.cameraObs)

  const date = Date.now()
  const timestamp = new Date(date - 60000).toISOString()
  const lat = location[0]
  const lng = location[1]
  const config = {
    limit: 900,
    time: timestamp,
    location: {
      lat: lat,
      lon: lng,
    },
    radius: 100,
    sensorVisMin: 0,
    sensorVisMax: 1,
    sensorPrecipMin: 0,
    sensorPrecipMax: 1,
    sensorRoadWeatherMin: 0,
    sensorRoadWeatherMax: 10,
    sort: 'time',
  }

  const url = getHeliosURL(lat, lng, config.radius)

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const e: Error = {
        name: '',
        message: 'An error occurred fetching cameras',
      }
      return [null, e as Error]
    }

    const data = await response.json()

    const normalizedFeatures: Array<CamFeature> =
      data.features.map(normalizeCamera)
    return [normalizedFeatures, null]
  } catch (error) {
    if (error.name === 'AbortError') {
      const e = new Error('Camera Network Request timed out. Please try again.')
      return [null, e]
    } else {
      console.log('Camera Network request error:', error)
      console.log('Camera Network request error:', error)
      return [null, error as Error]
    }
  }
}

function getHeliosURL(lat: number, long: number, rad = 100, limit = 200) {
  const minTime = new Date()
  minTime.setHours(minTime.getHours() - 1)
  const time = minTime.toISOString()
  const url = `${TWS_API_URL}/cameraobs?limit=${limit}&time_min=${time}&lat=${lat}&lon=${long}&radius=${rad}mi&sensors[visibility][min]=0&sensors[visibility][max]=1&sensors[precip][min]=0&sensors[precip][max]=1&sensors[road_weather][min]=0&sensors[road_weather][max]=10&sort=time`
  return url
}

export function normalizeCamera(feature: FeatureType) {
  const camType =
    'ObjectID' in feature.properties
      ? 'nddot'
      : 'type' in feature.properties
      ? 'airmarket'
      : 'helios'
  const cameras: Array<Camera> = []

  let roadWeather = null
  let visibility = null
  let precip = null

  if (feature.properties.hasOwnProperty('sensors')) {
    if (feature.properties?.sensors?.hasOwnProperty('road_weather')) {
      const prop = feature.properties.sensors
      if (prop.hasOwnProperty('road_weather')) {
        switch (prop?.road_weather?.data) {
          case 10:
            roadWeather = 'Full snow'
            break
          case 6:
            roadWeather = 'Partial snow'
            break
          case 3:
            roadWeather = 'Ponding'
            break
          case 2:
            roadWeather = 'Wet'
            break
          case 1:
            roadWeather = 'Moist'
            break
          case 0:
            roadWeather = 'Dry'
            break
          default:
            roadWeather = null
            break
        }
      }
    }

    if (feature?.properties?.sensors?.hasOwnProperty('visibility')) {
      const prop = feature.properties.sensors
      if (prop.hasOwnProperty('visibility')) {
        switch (prop?.visibility?.data) {
          case 1:
            visibility = 'Full'
            break
          case 0.5:
            visibility = 'Partial'
            break
          case 0.2:
            visibility = 'Reduced'
            break
          case 0:
            visibility = 'Poor'
            break
          default:
            visibility = null
            break
        }
      }
    }
    if (feature?.properties?.sensors?.hasOwnProperty('Precip')) {
      const prop = feature.properties.sensors
      if (prop.hasOwnProperty('precip')) {
        switch (prop?.visibility?.data) {
          case 1:
            precip = 'Heavy - Roads are significantly wet'
            break
          case 0:
            precip = 'Not heavy'
            break
          default:
            precip = null
            break
        }
      }
    }
  }

  // normalize cameras
  if (camType === 'helios') {
    cameras.push({
      id: feature.id,
      description: feature.properties.description ?? null,
      linkPath: `${TWS_API_URL}/cameraobs/${feature.properties.observation_id}/preview`,
      fullPath: `${TWS_API_URL}/cameraobs/${feature.properties.observation_id}/preview`,
      direction: null,
    })
  } else if (camType === 'nddot') {
    feature.properties?.Cameras?.forEach((camera) => {
      cameras.push({
        id: feature.id.toString(),
        description: camera.Description ?? null,
        linkPath: camera.LinkPath ?? null,
        fullPath: camera.FullPath,
        direction: camera.Direction ?? null,
      })
    })
  } else if (camType === 'airmarket') {
    feature.properties?.Cameras?.forEach((camera) => {
      cameras.push({
        id: camera.camera_id ?? null,
        description: camera.Description ?? null,
        linkPath: camera.LinkPath ?? null,
        fullPath: camera.fullPath,
        direction: camera.Direction ?? null,
        time: camera.time ?? null,
      })
    })
  }

  const outputFeature: CamFeature = {
    geometry: feature.geometry,
    properties: {
      roadWeather: {
        label: roadWeather !== null ? 'Road Weather' : null,
        value: roadWeather,
      },
      visibility: {
        label: visibility !== null ? 'Visibility' : null,
        value: visibility,
      },
      precip: {
        label: precip !== null ? 'Precipitation' : null,
        value: precip,
      },
      cameras: {
        label: null,
        value: cameras,
      },
      cameraId: {
        label: null,
        value:
          camType === 'helios' || camType === 'airmarket'
            ? feature.properties?.camera_id
            : feature.properties?.ObjectID,
      },
      prevID: {
        label: null,
        value:
          camType === 'helios'
            ? feature.properties.prev_id
            : feature.properties.ObjectID,
      },
      description: {
        label: 'Description',
        value:
          camType === 'helios' || camType === 'airmarket'
            ? feature.properties.description
            : feature.properties.Region,
      },
      city: {
        label: camType === 'helios' ? 'City' : null,
        value: camType === 'helios' ? feature.properties.city : null,
      },
      region: {
        label: 'Region',
        value:
          camType === 'helios' || camType === 'airmarket'
            ? feature.properties.region
            : feature.properties.Region,
      },
      state: {
        label: 'State',
        value: camType === 'helios' ? feature.properties.state : 'North Dakota',
      },
      country: {
        label: 'Country',
        value:
          camType === 'helios' ? feature.properties.country : 'United States',
      },
      time: {
        label: camType === 'helios' || 'airmarket' ? 'Date & Time' : null,
        value:
          camType === 'helios' || 'airmarket' ? feature.properties.time : null,
        label: camType === 'helios' || 'airmarket' ? 'Date & Time' : null,
        value:
          camType === 'helios' || 'airmarket' ? feature.properties.time : null,
      },
    },
    type: 'Feature',
    id:
      camType === 'helios'
        ? feature?.id
        : camType === 'airmarket'
        ? feature.properties?.camera_id
        : feature.properties?.ObjectID,
    id:
      camType === 'helios'
        ? feature?.id
        : camType === 'airmarket'
        ? feature.properties?.camera_id
        : feature.properties?.ObjectID,
  }
  return outputFeature
}
