import { useContext, ReactElement, useState } from "react";
import TWSContext from './TWSMapContext';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { addComponentKey, removeComponentKey } from "../utils/ComponentSlice";

/**
 * Custom React hook to manage elements in the TWS map.
 *
 * @returns {Object} An object containing the following properties and functions:
 *   - twsElements: An array of elements currently present in the TWS map.
 *   - addToTWS: A function to add an element to the TWS map.
 *     @param {Object} element - The element to be added to the TWS map. It must have a unique key property.
 *   - removeFromTWS: A function to remove an element from the TWS map.
 *     @param {Object} element - The element to be removed from the TWS map. It must have a unique key property.
 */
export default function useTWSMapContext() {
  const context = useContext(TWSContext);
  const dispatch = useDispatch();
  const componentKeys = useSelector((state: RootState) => state.components.componentKeys);

  if (!context) {
    throw new Error('useTWSMapContext must be used within a TWSProvider');
  }

  const { twsElements, setTwsElements } = context;

  /**
   * Add an element to the TWS map.
   *
   * @param {Object} element - The element to be added to the TWS map. It must have a unique key property.
   */
  const addToTWS = (element: ReactElement) => {
    const elementKey = element.key?.toString();

    if (!elementKey) {
      throw new Error('Element passed to addToTWS must have a key');
    }

    if (componentKeys.includes(elementKey)) {
      return;
    }

    if (twsElements.some(el => el.key === elementKey)) {
      return;
    }

    setTwsElements((prevTwsElements) => [...prevTwsElements, element]);
    dispatch(addComponentKey(elementKey));
  };

  /**
   * Remove an element from the TWS map.
   *
   * @param {Object|string|number} element - The element or its key value to be removed from the TWS map.
   */
  const removeFromTWS = (elementKey: React.Key) => {
    const key = elementKey.toString();
    setTwsElements((prevTwsElements) => prevTwsElements.filter((el) => el.key !== key));
    dispatch(removeComponentKey(key));
  };

  return { twsElements, addToTWS, removeFromTWS };
}
