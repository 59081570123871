import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    savedProfiles: [],
};
export const SET_SAVED_PROFILES = 'SET_SAVED_PROFILES';

export const SavedViewSlice = createSlice({
    name: 'savedView',
    initialState,
    extraReducers: (builder) => {
      builder.addCase('RESET_STORE', () => initialState)
    },
    reducers: {
        setSavedProfile: (state, action) => {
            state.savedProfiles = action.payload
          },
    }})
export const {
    setSavedProfile
} = SavedViewSlice.actions
export default SavedViewSlice.reducer